// extracted by mini-css-extract-plugin
export var ourContacts = "ourContacts-module--ourContacts--2WZmu";
export var socialLinks = "ourContacts-module--socialLinks--2VMWT";
export var link = "ourContacts-module--link--2yBH0";
export var linkWrap = "ourContacts-module--linkWrap--DhUHM";
export var linkText = "ourContacts-module--linkText--8dvWi";
export var linkIcon = "ourContacts-module--linkIcon--1OK8q";
export var descriptions = "ourContacts-module--descriptions--1zL2-";
export var title = "ourContacts-module--title--3IALL";
export var subTitle = "ourContacts-module--subTitle--eTxWl";
export var workTime = "ourContacts-module--workTime--17fXA";
export var addresOfWork = "ourContacts-module--addresOfWork--1Zzv_";
export var technicalSupport = "ourContacts-module--technicalSupport--2-6rd";
export var subDescription = "ourContacts-module--subDescription--3jEyX";
export var smallDescription = "ourContacts-module--smallDescription--1GV_o";
export var supportEmail = "ourContacts-module--supportEmail--1FuHm";
export var orderService = "ourContacts-module--orderService--jM9ss";