// extracted by mini-css-extract-plugin
export var sliderWrap = "sliderAboutDesinfection-module--sliderWrap--2p4B-";
export var sliderItemsWrap = "sliderAboutDesinfection-module--sliderItemsWrap--2S1nc";
export var navigationOfSlider = "sliderAboutDesinfection-module--navigationOfSlider--1zvCT";
export var navigationButton = "sliderAboutDesinfection-module--navigationButton--3Mm2m";
export var arrowIcon = "sliderAboutDesinfection-module--arrowIcon--1j0Fy";
export var arrowIconCircle = "sliderAboutDesinfection-module--arrowIconCircle--1tUxZ";
export var item = "sliderAboutDesinfection-module--item--1DtAZ";
export var imgWrap = "sliderAboutDesinfection-module--imgWrap--2Bl98";
export var img = "sliderAboutDesinfection-module--img--2gYBp";
export var title = "sliderAboutDesinfection-module--title--ZIsLT";
export var description = "sliderAboutDesinfection-module--description--2gwU9";
export var more = "sliderAboutDesinfection-module--more--3_rtV";