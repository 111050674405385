// extracted by mini-css-extract-plugin
export var ourServices = "ourServices-module--ourServices--2Resr";
export var title = "ourServices-module--title--28Jnq";
export var servicesItems = "ourServices-module--servicesItems--2c_4T";
export var item = "ourServices-module--item--23WAT";
export var linkToDetail = "ourServices-module--linkToDetail--2x_Lt";
export var arrowIcon = "ourServices-module--arrowIcon--1TqnD";
export var itemIconWrap = "ourServices-module--itemIconWrap--5WNMN";
export var itemIcon = "ourServices-module--itemIcon--1JsUH";
export var servicesTitle = "ourServices-module--servicesTitle--3DkvI";
export var servicesDescription = "ourServices-module--servicesDescription--3LKJj";